<template>
  <div class="contact-page">
    <sub-banner>
      <template v-slot:text>
        <h1 class="fw-bold">Privacy verklaring</h1>
      </template>
    </sub-banner>
    <div class="container-xxl">
      <div class="container">
        <div class="container">
          <p><b>De organisatie van de Landgoed Morren Run</b>, hierna te noemen Organisatie LMR, hecht veel waarde aan de bescherming van uw persoonsgegevens. In deze Privacy policy willen we heldere en transparante informatie geven over hoe wij omgaan met persoonsgegevens. Wij doen er alles aan om uw privacy te waarborgen en gaan daarom zorgvuldig om met persoonsgegevens. Organisatie LMR houdt zich in alle gevallen aan de toepasselijke wet- en regelgeving, waaronder de Algemene Verordening Gegevensbescherming. Dit brengt met zich mee dat wij in ieder geval:</p>
          <ul>
            <li>Uw persoonsgegevens verwerken in overeenstemming met het doel waarvoor deze zijn verstrekt, deze doelen en type persoonsgegevens zijn beschreven in deze Privacy policy;</li>
            <li>Verwerking van uw persoonsgegevens beperken tot enkel die gegevens welke minimaal nodig zijn voor de doeleinden waarvoor ze worden verwerkt;</li>
            <li>Vragen om uw uitdrukkelijke toestemming als wij deze nodig hebben voor de verwerking van uw persoonsgegevens;</li>
            <li>Passende technische en organisatorische maatregelen hebben genomen zodat de beveiliging van uw persoonsgegevens gewaarborgd is;</li>
            <li>Geen persoonsgegevens doorgeven aan andere partijen, tenzij dit nodig is voor uitvoering van de doeleinden waarvoor ze zijn verstrekt;</li>
            <li>Op de hoogte zijn van uw rechten omtrent uw persoonsgegevens, u hierop willen wijzen en deze respecteren.</li>
          </ul>
          <p>Als Organisatie LMR zijn wij verantwoordelijk voor de verwerking van uw persoonsgegevens. Indien u na het doornemen van ons Privacy policy, of in algemenere zin, vragen heeft hierover of contact met ons wenst op te nemen kan dit via de contactgegevens onder aan dit document.</p>

          <h3>Verwerking van persoonsgegevens</h3>
          <p>Persoonsgegevens worden door Organisatie LMR verwerkt ten behoeve van de volgende doelstelling(en):</p>
          <ul>
            <li>Het informeren van de persoon d.m.v. nieuwsuitingen via mailberichten en het publiceren van nieuwsuitingen op de website, facebook, X en andere media.</li>
          </ul>
          <p>Grondslag voor deze persoonsgegevens is:</p>
          <ul>
            <li>De inschrijving bij Organisatie LMR</li>
          </ul>
          <p>Voor de bovenstaande doelstelling(en) kan Organisatie LMR de volgende persoonsgegevens van u gebruiken:</p>
          <ul>
            <li>Voornaam;</li>
            <li>Tussenvoegsel;</li>
            <li>Achternaam;</li>
            <li>E-mailadres;</li>
            <li>Foto- en filmmateriaal.</li>
          </ul>
          <p>Uw persoonsgegevens worden door Organisatie LMR opgeslagen ten behoeve van bovengenoemde verwerking(en) voor de periode:</p>
          <ul>
            <li>Gedurende de periode dat men aangemeld is.</li>
          </ul>

          <h3>Verstrekking aan derden</h3>
          <p>De gegevens die u aan ons geeft kunnen wij aan derde partijen verstrekken indien dit noodzakelijk is voor uitvoering van de hierboven beschreven doeleinden.</p>
          <p>Zo maken wij gebruik van een derde partij voor:</p>
          <ul>
            <li>De registratie van inschrijving/uitslagen bij wedstrijden (inschrijven.nl/uitslagen.nl)</li>
          </ul>
          <p>Wij geven nooit persoonsgegevens door aan andere partijen waarmee we geen contract hebben afgesloten. Met deze partijen maken wij hierin uiteraard de nodige afspraken om de beveiliging van uw persoonsgegevens te waarborgen. Verder zullen wij de door uw verstrekte gegevens niet aan andere partijen verstrekken, tenzij dit wettelijk verplicht en toegestaan is. Een voorbeeld hiervan is dat de politie in het kader van een onderzoek (persoons)gegevens bij ons opvraagt. In een dergelijk geval dienen wij medewerking te verlenen en zijn dan ook verplicht deze gegevens af te geven. Tevens kunnen wij persoonsgegevens delen met derden indien u ons hier schriftelijk toestemming voor geeft.</p>

          <h3>Binnen de EU</h3>
          <p>Wij verstrekken geen persoonsgegevens aan partijen welke gevestigd zijn buiten de EU.</p>

          <h3>Minderjarigen</h3>
          <p>Wij verwerken enkel en alleen persoonsgegevens van minderjarigen (personen jonger dan 16 jaar) indien daarvoor bij inschrijving expliciet toestemming is gegeven door de ouder, verzorger of wettelijke vertegenwoordiger.</p>

          <h3>Bewaartermijn</h3>
          <p>Organisatie LMR bewaart persoonsgegevens niet langer dan noodzakelijk voor het doel waarvoor deze zijn verstrekt dan wel op grond van de wet is vereist.</p>

          <h3>Beveiliging</h3>
          <p>Wij hebben passende technische en organisatorische maatregelen genomen om persoonsgegevens van u te beschermen tegen onrechtmatige verwerking, zo hebben we bijvoorbeeld de volgende maatregelen genomen:</p>
          <ul>
            <li>Alle personen die namens Organisatie LMR van uw gegevens kennis kunnen nemen, zijn gehouden aan geheimhouding daarvan.</li>
            <li>We hanteren een gebruikersnaam en wachtwoordbeleid op al onze systemen;</li>
            <li>We pseudonimiseren en zorgen voor de encryptie van persoonsgegevens als daar aanleiding toe is;</li>
            <li>We testen en evalueren regelmatig onze maatregelen;</li>
            <li>Onze medewerkers zijn geïnformeerd over het belang van de bescherming van persoonsgegevens.</li>
          </ul>

          <h3>Rechten omtrent uw gegevens</h3>
          <p>U heeft recht op inzage, rectificatie of verwijdering van de persoonsgegevens welke wij van u ontvangen hebben. Tevens kunt u bezwaar maken tegen de verwerking van uw persoonsgegevens (of een deel hiervan) door ons of door één van onze verwerkers. Ook heeft u het recht om de door u verstrekte gegevens door ons te laten overdragen aan uzelf of in opdracht van u direct aan een andere partij. Wij kunnen u vragen om u te legitimeren voordat wij gehoor kunnen geven aan voornoemde verzoeken. Mogen wij uw persoonsgegevens verwerken op basis van een door u gegeven toestemming hiertoe, dan heeft u altijd het recht deze toestemming in te trekken.</p>

          <h3>Klachten</h3>
          <p>Mocht u een klacht hebben over de verwerking van uw persoonsgegevens dan vragen wij u hierover direct contact met ons op te nemen. Komen wij er samen met u niet uit dan vinden wij dit natuurlijk erg vervelend. U heeft altijd het recht een klacht in te dienen bij de Autoriteit Persoonsgegevens, dit is de toezichthoudende autoriteit op het gebied van privacybescherming.</p>

          <h3>Vragen</h3>
          <p>Als u naar aanleiding van ons Privacy Statement nog vragen of opmerkingen heeft neem dan contact met ons op!</p>

          <h3>Contactgegevens</h3>
          <p>Organisatie LMR<br>
            Hogekamp 47<br>
            8096 MT Oldebroek<br>
            <a href="mailto:lmr@vsco.nl">lmr@vsco.nl</a></p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import TextImage from '@/components/TextImage.vue'
import SubBanner from "@/components/SubBanner";
import ListImage from "@/components/ListImage";

export default {
  name: "Privacy",
  components: {
    SubBanner,
    TextImage,
    ListImage
  },
  created() {
    window.scrollTo(0, 0)
  },
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss" scoped>

$gold: #D6AD60;
$blue: #217287;

p {
  margin: 12px 0;
}

h1, h2, h3, h4, h5, h6, ul {
  margin: 32px 0;
}

</style>
