<template>
  <div class="contact-page">
    <sub-banner>
      <template v-slot:text>
        <h1 class="fw-bold">Parcours</h1>
      </template>
    </sub-banner>
    <div class="container-xxl">
      <div class="container">
        <div class="row g-5 align-items-center">
          <div class="col-lg-6">
            <h1 class="mb-4" style="font-weight: 700">Het parcours</h1>
            <p class="mb-4" style="font-size: 18px">De start en finish is op sportpark De Heughte. Vanaf het sportpark loopt het parcours via de wijk
              Oosterburgh en Landgoed Morren via de Oostendorperstraatweg weer naar het sportpark. De
              lopathon voor de jeugd is op het hoofdveld van het sportpark.</p>
          </div>
          <div class="col-lg-6">
            <img alt="landgoed morren run parcours"
                 src="../assets/images/Parcours.jpg"
                 style="position: relative; width: 100%;">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextImage from '@/components/TextImage.vue'
import SubBanner from "@/components/SubBanner";
import ListImage from "@/components/ListImage";

export default {
  name: "About",
  components: {
    SubBanner,
    TextImage,
    ListImage
  },
  created() {
    window.scrollTo(0, 0)
  },
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss" scoped>

$gold: #D6AD60;
$blue: #217287;

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 200px;
    height: auto;
    margin: 20px;
  }
}

</style>
