import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Parcours from '../views/Parcours.vue'
import Informatie from '../views/Informatie.vue'
import Privacy from '../views/Privacy.vue'

function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'home',
    props: true,
    component: HomeView,
    meta: {
      title: "Landgoed Morren Run | Oosterwolde"
    }
  },
  {
    path: '/parcours',
    name: 'parcour',
    props: true,
    component: Parcours,
    meta: {
      title: "Parcours | Landgoed Morren Run"
    }
  },
  {
    path: '/informatie',
    name: 'informatie',
    props: true,
    component: Informatie,
    meta: {
      title: "Praktische informatie | Landgoed Morren Run"
    }
  },
  {
    path: '/sponsors',
    name: 'Sponsors',
    props: true,
    component: lazyLoad('Sponsors'),
    meta: {
      title: "Sponsors | Landgoed Morren Run"
    }
  },
  {
    path: '/fotos',
    name: 'Fotos',
    props: true,
    component: lazyLoad('Fotos'),
    meta: {
      title: "Fotos | Landgoed Morren Run"
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    props: true,
    component: lazyLoad('Privacy'),
    meta: {
        title: "Privacy | Landgoed Morren Run"
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
})

export default router
