<template>
  <div class="contact-page">
    <sub-banner>
      <template v-slot:text>
        <h1 class="fw-bold">Praktische informatie</h1>
      </template>
    </sub-banner>
    <div class="container-xxl">
      <div class="container">
        <div class="row g-5 align-items-center">
          <div class="col-lg-6">
            <h2 style="font-weight: 700; margin-bottom: 16px;">Programma</h2>
            <div class="schedule">
              <table class="table">
                <thead>
                <tr>
                  <th>Tijd</th>
                  <th>Activiteit</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td><b>18:00 uur</b></td>
                  <td>Start 2,5 en 10 km</td>
                </tr>
                <tr>
                  <td><b>18:10 - 18:25 uur</b></td>
                  <td>Lopathon jeugd groep I - warming up</td>
                </tr>
                <tr>
                  <td><b>18:30 uur</b></td>
                  <td>Start lopathon jeugd groep I</td>
                </tr>
                <tr>
                  <td><b>19:10 - 19:25 uur</b></td>
                  <td>Lopathon jeugd groep II - warming up</td>
                </tr>
                <tr>
                  <td><b>19:15 uur</b></td>
                  <td>Start 5 km</td>
                </tr>
                <tr>
                  <td><b>19:30 uur</b></td>
                  <td>Start lopathon jeugd groep II</td>
                </tr>
                <tr>
                  <td><b>20:15 uur</b></td>
                  <td>Prijsuitreiking</td>
                </tr>
                </tbody>
              </table>
            </div>
            <a class="btn btn-primary py-3 px-5 mt-2" href="/Sponsorlijst.docx" target="_blank">Download sponsorlijst</a>
          </div>
          <div class="col-lg-6">
            <h2 style="font-weight: 700; font-size: 36px; margin-bottom: 16px; line-height: 40px;">Afstanden, categorieën en kosten</h2>
            <div class="fees">
              <table class="table">
                <thead>
                <tr>
                  <th>Afstand</th>
                  <th>Categorie</th>
                  <th>Kosten</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td><b>2,5 km</b></td>
                  <td>mannen</td>
                  <td>€ 10,00</td>
                </tr>
                <tr>
                  <td><b>2,5 km</b></td>
                  <td>vrouwen</td>
                  <td>€ 10,00</td>
                </tr>
                <tr>
                  <td><b>5,0 km</b></td>
                  <td>mannen</td>
                  <td>€ 10,00</td>
                </tr>
                <tr>
                  <td><b>5,0 km</b></td>
                  <td>vrouwen</td>
                  <td>€ 10,00</td>
                </tr>
                <tr>
                  <td><b>10,0 km</b></td>
                  <td>mannen</td>
                  <td>€ 10,00</td>
                </tr>
                <tr>
                  <td><b>10,0 km</b></td>
                  <td>vrouwen</td>
                  <td>€ 10,00</td>
                </tr>
                </tbody>
              </table>
              <a class="btn btn-primary py-3 px-5 mt-2" href="https://inschrijven.nl/form/2024091351516-nl" target="_blank">Inschrijven</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-xxl">
      <div class="container">
        <div class="row g-5 align-items-center">
          <div class="col-lg-6">
            <h2 style="font-weight: 700">Locaties</h2>
            <div class="locations">
              <p><b>Start en finish</b></p>
              <p>Sportpark De Heughte</p>
              <p>Zwarteweg 15</p>
              <p>8097 PR Oosterwolde</p>
            </div>
            <br>
            <br>
            <div class="parking">
              <h2 style="font-weight: 700">Parkeren</h2>
              <p><b>Parkeerterrein sportpark De Heughte</b></p>
              <p>Zwarteweg 15</p>
              <p>8097 PR Oosterwolde</p>
              <p><b>Parkeerterrein tegenover sportpark De Heughte</b></p>
              <p><b>Parkeerterrein Sint Nicolaasplein te 8097 PR Oosterwolde</b></p>
            </div>
          </div>
          <div class="col-lg-6">
            <h2 style="font-weight: 700; font-size: 36px">Wedstrijdsecretariaat <br> (Vanaf 17:00 uur)</h2>
            <p><b>Clubhuis sportpark De Heughte</b></p>
            <p>Zwarteweg 15</p>
            <p>8097 PR Oosterwolde</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextImage from '@/components/TextImage.vue'
import SubBanner from "@/components/SubBanner";
import ListImage from "@/components/ListImage";

export default {
  name: "About",
  components: {
    SubBanner,
    TextImage,
    ListImage
  },
  created() {
    window.scrollTo(0, 0)
  },
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss" scoped>


table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  th {
    background-color: black;
    color: white;
    font-size: 18px;
  }


  td {
    font-size: 18px;
  }
}

p {
  font-size: 18px;
}

$gold: #D6AD60;
$blue: #217287;

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  img {
    width: 200px;
    height: auto;
    margin: 20px;
  }
}

</style>
